<script setup>
import { useAuthStore } from '~/stores/auth';
import { useCommonStore } from '~/stores/common';

const commonStore = useCommonStore();
const authStore = useAuthStore();
const config = useRuntimeConfig();

const onTapProfile = () => {
    commonStore.showSidebar = true;
};
</script>

<template>
    <nav class="navbar navbar-dark">
        <div class="navbar-container container-lg">
            <div class="navbar-top-row">
                <a class="navbar-brand" href="/">
                    <img src="/images/logo-colorful.png" :alt="config.public.appName" class="logo">
                    <img v-if="config.public.isDomino" src="/images/logo-2col-colorful.png" :alt="config.public.appName"
                        class="logo logo-sm">
                    <img v-else src="/images/logo-colorful.png" :alt="config.public.appName" class="logo logo-sm">
                </a>

                <div v-if="authStore.user" class="navbar-user-balance only-sm">
                    <HeaderProfileBlock :balanceLeft="true" />
                </div>
                <div v-else class="navbar-user-button navbar-signin only-sm">
                    <template v-if="config.public.isDomino">
                        <DLink to="/auth/login">
                            <span>Войдите</span>, чтобы <br>
                            копить и списывать баллы
                        </DLink>
                    </template>
                    <template v-else>
                        <DButton :link="'/auth/login'">Войти</DButton>
                    </template>
                </div>
            </div>

            <div class="navbar-user-block" :class="{ '': authStore.address || authStore.user }">


                <HeaderAddressBlock />

                <div v-if="authStore.user" class="navbar-user-balance only-lg">
                    <HeaderProfileBlock />
                </div>
                <div v-else class="navbar-user-button navbar-signin only-lg">
                    <template v-if="config.public.isDomino">
                        <DLink to="/auth/login">
                            <span>Войдите</span>, чтобы <br>
                            копить и списывать баллы
                        </DLink>
                    </template>
                    <template v-else>
                        <DButton :link="'/auth/login'">Войти</DButton>
                    </template>
                </div>
            </div>
        </div>
    </nav>
</template>

<style lang="scss" scoped>
.navbar {
    @if $is-domino {
        background-color: #fff;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.03);
    }

    @if not $is-domino {
        @media screen and (max-width: 900px) {
            padding-left: 12px;
            padding-right: 12px;
        }
    }

}

.navbar-signin {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 20px;
    font-size: 14px;
    line-height: 1.1;

    a {
        color: #000;
        text-decoration: none;
        cursor: pointer;

        span {
            color: $primary;
            font-weight: 500;
        }

        &:hover {
            span {
                color: lighten($primary, 10);
            }
        }
    }

    &.only-sm {
        text-align: right;
        font-size: 14px;
        vertical-align: middle;
    }

}

.navbar-user-balance {
    display: flex;

    &.only-sm {
        display: none;

        @media screen and (max-width: 900px) {
            display: flex;
            margin-left: auto;
            margin-top: auto;
            margin-bottom: auto;
            margin-right: 0px;
        }
    }

    &.only-lg {
        display: none;

        @media screen and (min-width: 900px) {
            display: flex;
        }
    }

}

.navbar-user-button {
    display: block;
    order: 2;

    &.only-lg {
        @media screen and (max-width: 900px) {
            display: none;
        }
    }

    &.only-sm {
        margin-left: auto;
        margin-top: 6px;

        @media screen and (min-width: 900px) {
            display: none;
        }
    }
}

.navbar {
    padding-top: if($is-domino, 0, 10px);
    padding-bottom: 0;

    .navbar-container {
        padding: if($is-domino, 16px 20px, 4px 20px);

        @if not $is-domino {
            position: relative;
            background-color: #fff;
            border-radius: 10px;
            padding: 10px 20px;
            border: 1px solid #000;
            top: -1px;
        }

        @if $is-domino {
            border-top: none;
            padding-bottom: 14px;
        }

        .navbar-top-row {
            display: flex;
            flex-direction: row;

            @media screen and (max-width: 900px) {
                width: 100%;
            }

            .navbar-sm-user-button {
                display: none;

                @media screen and (max-width: 900px) {
                    display: block;
                    margin-left: auto;
                    margin-top: 6px;
                }
            }
        }

        .navbar-user-block {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;


            @media screen and (max-width: 900px) {
                // margin-top: 20px;
                width: 100%;
                border-top: 1px solid #0000001a;
                padding-top: 16px;
                margin-top: 10px;
            }

            @media screen and (max-width: 375px) {
                flex-direction: column;

                .navbar-address {
                    width: 100%;
                    max-width: 100%;
                    margin-bottom: 10px;
                    order: 3;

                    span {
                        width: 100%;
                        max-width: 100%;
                    }
                }
            }
        }

        @media screen and (max-width: 900px) {
            flex-direction: column;
        }
    }

    .navbar-brand {
        @media screen and (max-width: 900px) {
            margin-right: 0 !important;
        }

        .logo {
            max-height: if($is-domino, 20px, 47px);
            margin-left: 40px;
            display: inline-block;

            &.logo-sm {
                display: none;
            }

            @media screen and (max-width: 900px) {
                margin-left: 0;
                max-height: 30px;
                display: none;

                &.logo-sm {
                    display: inline-block;
                }
            }
        }
    }


}
</style>